import type { Invoice, MonthlyInvoices } from '../../types/mpay.types';
import { getMonthFromDateTranslation } from './translations';

export function getInvoiceById(
  invoices: MonthlyInvoices,
  id: string
): Invoice | null {
  let foundInvoice = null;

  Object.keys(invoices).forEach((month) => {
    const currResult = invoices[month].find((invoice) => invoice.number === id);

    if (currResult) {
      foundInvoice = currResult;
    }
  });

  return foundInvoice;
}

export function getSortedInvoicesByMonth(invoices: Invoice[]): MonthlyInvoices {
  return invoices.reduce((acc: MonthlyInvoices, curr) => {
    const invMonth = getMonthFromDateTranslation(curr.date);

    acc[invMonth] = [...(acc[invMonth] || []), curr];

    return acc;
  }, {});
}

export function getMergedInvoices(
  oldVal: MonthlyInvoices,
  newVal: MonthlyInvoices
): MonthlyInvoices {
  if (Object.keys(oldVal).length > 0) {
    Object.keys(newVal).forEach((key) => {
      if (oldVal[key]) {
        oldVal[key].push(...newVal[key]);
      } else {
        oldVal[key] = newVal[key];
      }
    });
  }

  return Object.keys(oldVal).length > 0 ? oldVal : newVal;
}
