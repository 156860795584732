<script lang="ts">
  import { t } from 'svelte-i18n';
  import LinkButton from './LinkButton.svelte';
  import { fade, slide } from 'svelte/transition';

  export let isOpen: boolean;
  export let setIsOpen: () => void;
  export let title: string;
  export let testId: string | undefined = undefined;
</script>

{#if isOpen}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <div
    data-cy={testId}
    transition:fade={{ duration: 300 }}
    class={`${isOpen ? 'absolute bottom-0 left-0 right-0 top-0 z-40 h-full bg-semantic-neutral/30' : ''}`}
    on:click={setIsOpen}
  />

  <div
    transition:slide={{ duration: 300 }}
    class={`fixed bottom-0 left-0 right-0 z-50 flex h-fit flex-col items-center rounded-t-3xl bg-white pb-6 pt-4 transition-all duration-300 ${isOpen ? 'translate-y-0' : 'translate-y-full'}`}
  >
    <button
      class="h-1 w-10 rounded-xl bg-primary-tint90"
      on:click={setIsOpen}
    />

    <div class="mt-2 flex w-full items-center justify-between px-5">
      <h3
        data-cy="bottom-sheet-title"
        class="text-android-title-large text-semantic-neutral ios:text-ios-title2 ios:font-semibold"
      >
        {title}
      </h3>
      <LinkButton
        id="bottom-sheet-cancel-button"
        label={$t('general.cta.cancel')}
        onClick={setIsOpen}
      />
    </div>

    <div class="w-full px-5 pt-2">
      <slot />
    </div>
  </div>
{/if}
