import { locale } from 'svelte-i18n';
import { locales } from '../../i18n';
import { get } from 'svelte/store';

export function getMonthFromDateTranslation(date: string) {
  const $locale = get(locale);

  return new Date(date).toLocaleString($locale || locales.en.dateLocale, {
    month: 'long',
  });
}
