import { posthog, PostHogPluginMissingError } from '../../plugins/posthog';

export class PostHogFeatureFlagsError extends Error {
  constructor() {
    super('PostHog onFeatureFlags error');
    this.name = 'PostHogFeatureFlagsError';
  }
}

export const featureFlagsNames = {
  repayment: 'repayment',
  payment_status: 'payment_status',
};

const featureFlagsStatus: {
  [key in keyof typeof featureFlagsNames]: boolean;
} = {
  repayment: false,
  payment_status: false,
};

export function initFeatureFlags(): Promise<void> {
  return new Promise((resolve, reject) => {
    if (!posthog) {
      reject(new PostHogPluginMissingError());
      return;
    }
    if (!posthog.onFeatureFlags) {
      reject(new PostHogFeatureFlagsError());
      return;
    }

    posthog.onFeatureFlags(() => {
      if (posthog.isFeatureEnabled(featureFlagsNames.repayment)) {
        featureFlagsStatus.repayment = true;
      }

      if (posthog.isFeatureEnabled(featureFlagsNames.payment_status)) {
        featureFlagsStatus.payment_status = true;
      }

      resolve();
    });
  });
}

export default featureFlagsStatus;
