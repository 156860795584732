<script lang="ts">
  import Layout from '../layouts/main.svelte';
  import { posthog } from '../../plugins/posthog';
  import { routes } from '../../routes';
  import type { Invoice } from '../../types/mpay.types';
  import { navigate } from 'svelte-routing';
  import { t } from 'svelte-i18n';
  import { getFormattedTimestamp } from '../utils/formatters';
  import {
    onDestroy,
    onMount,
    type ComponentType,
    type SvelteComponent,
  } from 'svelte';
  import { EventsMapKeys, trackEvents } from '../utils/tracking';
  import { Context, Target } from '../utils/analytics/keys';
  import { getInvoiceById } from '../utils/invoices';
  import { showLoader } from '../JSBridge/loaderController';
  import { topBar } from '../../store/TopBarStore';
  import { invoicesStore } from '../../store/invoicesStore';
  import Button from '../components/Button.svelte';
  import { isAndroid } from '../utils/detectPlatform';
  import { mCreditStore } from '../../store/mCreditStore';
  import InvoicePaymentStatus from '../components/InvoicePaymentStatus.svelte';
  import featureFlagsStatus from '../utils/featureFlags';

  export let id = '';

  let invoice: Invoice | null = null;
  let href: string = '';
  let formattedDateTime = '';
  let pdfComponent: ComponentType<SvelteComponent>;
  let isPdfComponentLoading = true;
  let isPdfFileLoaded = false;
  let currentPage = 1;
  let pageCount = 1;

  posthog.capture('$pageview');
  showLoader();

  onMount(async () => {
    invoice =
      getInvoiceById($invoicesStore.invoices, id) ||
      getInvoiceById($mCreditStore.invoices, id);
    if (!invoice) return navigate(routes.home.path);

    topBar.setButton({
      label: $t('buttons.details'),
      onClick: () => {
        trackEvents(EventsMapKeys.CTA_CLICK, {
          category: Context.Invoice,
          target: Target.PaymentDetails,
        });

        navigate(href);
      },
    });

    topBar.setContext(Context.Invoice);

    href = routes.invoiceDetails.path + '/' + invoice.number;

    formattedDateTime = getFormattedTimestamp(invoice.date);

    try {
      const loadedComp = await import('../components/PdfViewer.svelte');
      pdfComponent = loadedComp.default;
    } catch {
      console.error('Failed to load PDF viewer component');
    } finally {
      isPdfComponentLoading = false;
      // not calling hideLoader(); to make the "loader" experience smooth. It will be hidden from the PdfViewer component
      // loader has a "safety" timeout of 15 seconds
    }
  });

  onDestroy(() => {
    topBar.reset();
  });

  const onPdfLoaded = (e: { detail: number }) => {
    pageCount = e.detail;
    isPdfFileLoaded = true;
  };

  const goBack = () => {
    trackEvents(EventsMapKeys.CTA_CLICK, {
      category: Context.Invoice,
      target: Target.BackToOverview,
    });
    navigate(routes.home.path);
  };

  const goToDetails = () => {
    trackEvents(EventsMapKeys.CTA_CLICK, {
      category: Context.Invoice,
      target: Target.PaymentDetails,
    });
    navigate(href);
  };
</script>

<Layout>
  {#if invoice}
    {#if featureFlagsStatus.payment_status && invoice.mCreditPaymentStatus}
      <InvoicePaymentStatus
        paymentStatus={invoice.mCreditPaymentStatus}
        paymentDate={invoice.paymentDate}
        dueDate={invoice.dueDate}
      />
    {/if}

    <div
      class="flex h-9 items-center justify-center bg-semantic-attention px-4 text-android-body-medium text-primary-base ios:text-ios-callout"
    >
      {$t('invoice.noticeInfo')}
    </div>

    <div class="mb-4 inline-flex w-full flex-col gap-4 px-4 pt-6">
      <div class="inline-flex items-end self-stretch">
        <div class="inline-flex grow flex-col gap-0.5">
          <time
            class="text-android-title-large text-primary-base ios:text-ios-title2 ios:font-semibold"
          >
            {formattedDateTime}
          </time>
          <p
            class="text-android-body-medium text-primary-base ios:text-ios-callout"
          >
            {$t('invoice.title')}
            {invoice.number}
          </p>
        </div>

        <div
          class="flex items-center rounded-xl bg-primary-darken20 px-2 pb-px pt-px ios:pb-[3px]"
        >
          <p
            class="text-android-body-medium font-semibold text-white ios:text-ios-subhead"
          >
            {currentPage}/{pageCount}
          </p>
        </div>
      </div>

      {#if isPdfComponentLoading}
        <div />
      {:else}
        <div class="bg-white">
          <svelte:component
            this={pdfComponent}
            href={invoice.pdfLink}
            on:load={onPdfLoaded}
            on:pageChange={(e) => (currentPage = e.detail)}
          />
        </div>
      {/if}

      {#if isPdfFileLoaded}
        <div class="inline-flex items-start justify-center gap-2">
          {#each { length: pageCount } as _, i}
            <div
              class={[
                'h-2 w-2 rounded-full transition-all duration-300',
                currentPage === i + 1
                  ? 'bg-blue-base'
                  : 'bg-semantic-invertedMuted',
              ].join(' ')}
            />
          {/each}
        </div>

        {#if isAndroid()}
          <div class="flex flex-col gap-3 pt-4">
            <Button label={$t('buttons.back_to_overview')} onClick={goBack} />
            <Button
              label={$t('buttons.payment_details')}
              type="outlined"
              onClick={goToDetails}
            />
          </div>
        {/if}
      {/if}
    </div>
  {/if}
</Layout>
