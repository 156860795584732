import { writable } from 'svelte/store';
import type {
  CreditLimit,
  CreditSettle,
  MCreditSummary,
  MonthlyInvoices,
} from '../types/mpay.types';
import { CreditSettleFrequency, MPayApi } from '../lib/constants';
import type { Pagination } from '../types/globals.types';
import { sendRequest } from '../lib/utils/fetch';
import { MPAY_API_BASE_URI } from '../env';
import { getCountry, getAccountId } from '../lib/utils/localStorage';
import { errorDialogStore } from './errorDialogStore';

const initialState: {
  invoices: MonthlyInvoices;
  pagination: Pagination;
  currentPage: number;
  creditLimit: CreditLimit;
  creditSettle: CreditSettle;
  mCreditSummary: MCreditSummary;
} = {
  invoices: {},
  pagination: {
    currentPage: 0,
    totalPages: 0,
    total: 0,
  },
  currentPage: 1,
  creditLimit: {
    grantedCreditLimit: 0,
    exhaustedCreditLimit: 0,
    availableCreditLimit: 0,
    currencyCode: 'RON',
  },
  creditSettle: {
    creditSettleFrequencyCd: '',
    creditSettlePeriodCd: '',
    creditSettleTypeCd: '',
    creditTpContract: CreditSettleFrequency.EMPTY,
  },
  mCreditSummary: {
    dueAmount: 0,
    overdueAmount: 0,
    nextDueDate: '',
    currencyCode: 'RON',
  },
};

const createMCreditStore = () => {
  const { subscribe, update } = writable(initialState);

  return {
    subscribe,
    setInvoices: (data: {
      invoices: MonthlyInvoices;
      pagination: Pagination;
    }) =>
      update((state) => ({
        ...state,
        ...data,
      })),
    setCurrentPage: (value: number) =>
      update((state) => ({
        ...state,
        currentPage: value,
      })),
    setCreditLimit: (value: CreditLimit) =>
      update((state) => ({
        ...state,
        creditLimit: value,
      })),
    setCreditSettle: (value: CreditSettle) =>
      update((state) => ({
        ...state,
        creditSettle: value,
      })),
    setMCreditSummary: (value: MCreditSummary) =>
      update((state) => ({
        ...state,
        mCreditSummary: value,
      })),
  };
};

export const mCreditStore = createMCreditStore();

export const fetchCreditSettleDetails = async () => {
  try {
    const creditSettleResponse = await sendRequest<CreditSettle>(
      `${MPAY_API_BASE_URI}/${MPayApi.CREDIT_SETTLE}/${getCountry()}/${getAccountId()}`
    );

    mCreditStore.setCreditSettle(creditSettleResponse);
  } catch (error) {
    errorDialogStore.setRetryAction(fetchCreditSettleDetails);
  }
};
