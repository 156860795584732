<script lang="ts">
  import Layout from '../layouts/main.svelte';
  import { posthog } from '../../plugins/posthog';
  import { routes } from '../../routes';
  import type { Invoice } from '../../types/mpay.types';
  import { navigate } from 'svelte-routing';
  import { t } from 'svelte-i18n';
  import {
    getFormattedNumber,
    getFormattedTimestamp,
  } from '../utils/formatters';
  import { onMount } from 'svelte';
  import Icon from '../components/Icon.svelte';
  import { colors } from '../../assets/styles/cssColors';
  import type { IconName } from '../../types/icons.types';
  import { getInvoiceById } from '../utils/invoices';
  import { getMetroStoreById } from '../utils/metroStores';
  import { getCurrencyUnit } from '../utils/helpers';
  import { InvoiceType } from '../constants';
  import { topBar } from '../../store/TopBarStore';
  import { Context, Target } from '../utils/analytics/keys';
  import { invoicesStore } from '../../store/invoicesStore';
  import Button from '../components/Button.svelte';
  import { isAndroid } from '../utils/detectPlatform';
  import { EventsMapKeys, trackEvents } from '../utils/tracking';
  import PaymentMethodListItem from '../components/PaymentMethodListItem.svelte';
  import { mCreditStore } from '../../store/mCreditStore';
  import InvoicePaymentStatus from '../components/InvoicePaymentStatus.svelte';
  import featureFlagsStatus from '../utils/featureFlags';

  type InvoiceDetail = {
    label: string;
    value: string | number;
    iconName: IconName;
  };

  export let id = '';
  let invoice: Invoice | null = null;
  let invoiceDetails: InvoiceDetail[] = [];

  posthog.capture('$pageview');

  onMount(() => {
    invoice =
      getInvoiceById($invoicesStore.invoices, id) ||
      getInvoiceById($mCreditStore.invoices, id);
    if (!invoice) return navigate(routes.home.path);

    topBar.setContext(Context.InvoiceDetails);
    topBar.setTitle($t('invoice_details.invoice'));

    document.body.style.background = colors.white;

    // List items for the following invoice details
    invoiceDetails = [
      {
        label: $t('invoice_details.invoice'),
        value: invoice.number,
        iconName: 'Invoices',
      },
      {
        label: $t('invoice_details.gross_amount'),
        value:
          getFormattedNumber(invoice.grossAmount) +
          ' ' +
          getCurrencyUnit(invoice.currencyCode),
        iconName: 'PriceTags',
      },
      {
        label: $t('invoice_details.net_amount'),
        value:
          getFormattedNumber(invoice.netAmount) +
          ' ' +
          getCurrencyUnit(invoice.currencyCode),
        iconName: 'CashPaymentCoins',
      },
      {
        label: $t('invoice_details.tax'),
        value:
          getFormattedNumber(invoice.vatAmount) +
          ' ' +
          getCurrencyUnit(invoice.currencyCode),
        iconName: 'Tax',
      },
      {
        label: $t('invoice_details.timestamp'),
        value: getFormattedTimestamp(invoice.date) || '',
        iconName: 'Calendar',
      },
      {
        label: $t('invoice_details.invoice_type'),
        value: $t(`invoiceTypes.${invoice.invoiceType}`),
        iconName:
          invoice.invoiceType === InvoiceType.LEGA ? 'Business' : 'Cardholder',
      },
      {
        label: $t('invoice_details.merchant'),
        value: getMetroStoreById(invoice.storeId) || $t('general.unknown'),
        iconName: 'Shop',
      },
      {
        label: $t('invoice_details.card_holder'),
        value: invoice.cardHolder.firstName + ' ' + invoice.cardHolder.lastName,
        iconName: 'SingleManCircle',
      },
    ];
  });

  const goBack = () => {
    trackEvents(EventsMapKeys.CTA_CLICK, {
      category: $topBar.context,
      target: Target.BackToInvoiceButton,
    });
    window.history.back();
  };
</script>

<Layout>
  {#if invoice}
    {#if featureFlagsStatus.payment_status && invoice.mCreditPaymentStatus}
      <InvoicePaymentStatus
        paymentStatus={invoice.mCreditPaymentStatus}
        paymentDate={invoice.paymentDate}
        dueDate={invoice.dueDate}
      />
    {/if}

    <div class="my-4 grid gap-4 px-5" data-cy="invoice-details-list">
      <section>
        <p
          class="py-2 text-android-label-small font-medium text-primary-tint40 ios:text-ios-caption ios:font-semibold"
        >
          {$t('invoice_details.title')}
        </p>

        <ul>
          {#each invoiceDetails as invoice}
            <li
              class="flex gap-3 border-b border-primary-tint90 py-[14px] text-primary-base last:border-none"
            >
              <div class="rounded-lg bg-semantic-appBackground p-2.5">
                <Icon iconName={invoice.iconName} />
              </div>

              <div class="inline-flex flex-col">
                <p
                  class="text-android-title-medium font-medium ios:text-ios-headline ios:font-semibold"
                >
                  {invoice.value}
                </p>
                <p
                  class="text-android-body-small text-primary-tint40 ios:text-ios-footnote"
                >
                  {invoice.label}
                </p>
              </div>
            </li>
          {/each}
        </ul>
      </section>

      {#if invoice.paymentList?.length > 0}
        <section class="mb-4 grid gap-3 ios:gap-2">
          <div
            class="mr-4 flex justify-between py-2 text-android-label-small font-medium text-primary-tint40 ios:text-ios-caption ios:font-semibold"
          >
            <p>
              {$t('invoice_details.title')}
            </p>

            <p>
              {getCurrencyUnit(invoice.currencyCode)}
            </p>
          </div>

          {#each invoice.paymentList as payment}
            <PaymentMethodListItem {payment} />
          {/each}
        </section>
      {/if}
    </div>

    {#if isAndroid()}
      <div class="mb-6 mt-4 flex flex-col gap-3 px-4">
        <Button label={$t('buttons.back')} onClick={goBack} />
      </div>
    {/if}
  {/if}
</Layout>
