<script lang="ts">
  import { Route, Router } from 'svelte-routing';
  import Home from './lib/routes/Home.svelte';
  import { routes } from './routes';
  import { onMount } from 'svelte';
  import { isAndroid } from './lib/utils/detectPlatform';
  import Invoice from './lib/routes/Invoice.svelte';
  import InvoiceDetails from './lib/routes/InvoiceDetails.svelte';
  import { hideLoader } from './lib/JSBridge/loaderController';
  import Repayment from './lib/routes/Repayment.svelte';

  export let url = '';
  export let hasAuthError = false;

  onMount(() => {
    import('pdfjs-dist/build/pdf.worker.mjs');

    if (!isAndroid()) {
      document.body.classList.add('ios');
    }

    // hiding the loader triggered in main.ts file
    hideLoader();
  });
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="true" />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<Router {url}>
  <Route path={routes.home.path} component={Home} {hasAuthError} />
  <Route path={routes.invoice.path + '/:id'} let:params>
    <Invoice id={params.id} />
  </Route>
  <Route path={routes.invoiceDetails.path + '/:id'} let:params>
    <InvoiceDetails id={params.id} />
  </Route>
  <Route path={routes.repayment.path} component={Repayment} />
</Router>
