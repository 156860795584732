import { MethodType } from '../constants';

/**
 * Sends fetch request to the given endpoint with the request details.
 */
export async function sendRequest<T = unknown>(
  input: RequestInfo | URL,
  init?: RequestInit
): Promise<T> {
  try {
    const headers = new Headers({
      'Content-Type': 'application/json',
      ...init?.headers,
    });

    const token = window.localStorage.getItem('token');
    if (token) {
      headers.append('Authorization', `Bearer ${token}`);
    }

    const response = await fetch(input, {
      ...init,
      headers,
    });

    // TODO: remove this check once we have proper e2e tests
    if (response.status === 401 && import.meta.env.MODE !== 'test') {
      window.location.href = `${window.location.origin}/login?accountId=${window.localStorage.getItem('accountId')}`;
    }

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return (await response.json()) as T;
  } catch (error) {
    if (error instanceof TypeError) {
      console.warn('Network error:', error);
    } else if ((error as Error).name === 'AbortError') {
      console.warn('Request timeout:', error);
    } else {
      console.error('HTTP error:', error);
    }

    throw error; // re-throwing the error to be handled by the caller
  }
}

export type Request = {
  url: string;
  method?: MethodType | string;
  data?: BodyInit | null | undefined;
};
