<script lang="ts">
  import { isLoading } from 'svelte-i18n';
  import TopBar from '../components/TopBar.svelte';
  import ErrorDialog from '../components/ErrorDialog.svelte';
  import { errorDialogStore } from '../../store/errorDialogStore';
  import { isAndroid } from '../utils/detectPlatform';
</script>

<!-- loader for the i18n-->
{#if $isLoading}
  <div></div>
{:else}
  {#if !isAndroid()}
    <TopBar />
  {/if}

  <main class={!isAndroid() ? 'pt-topBar' : 'pt-0'}>
    <slot />
  </main>

  <ErrorDialog
    bind:showErrorDialog={$errorDialogStore.showDialog}
    onRetry={$errorDialogStore.actions}
  />
{/if}
