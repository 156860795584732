<script lang="ts">
  import { t } from 'svelte-i18n';
  import { PaymentStatus } from '../constants';
  import Badge from './Badge.svelte';
  import { getFormattedDate } from '../utils/formatters';

  export let paymentStatus: PaymentStatus;
  export let paymentDate: string;
  export let dueDate: string;
</script>

<section
  data-cy="invoice-payment-status"
  class="flex h-[91px] w-full bg-primary-darken20 px-5 py-4"
>
  <div class="flex grow basis-0 flex-col items-center gap-1 py-2">
    <span
      class="text-center text-android-body-small text-primary-tint95 ios:text-ios-footnote"
    >
      {$t('paymentStatus.status')}
    </span>
    <Badge type={paymentStatus} size={'lg'} outline />
  </div>

  <div
    class="flex grow basis-0 flex-col gap-1 border-l py-2 text-primary-tint20"
  >
    <span
      class="text-center text-android-body-small text-primary-tint95 ios:text-ios-footnote"
    >
      {$t(`paymentStatus.${paymentStatus}_date`)}
    </span>
    <span
      class="py-0.5 text-center text-android-body-medium text-primary-tint95 ios:text-ios-callout"
    >
      {getFormattedDate(
        paymentStatus === PaymentStatus.REPAID ? paymentDate : dueDate
      )}
    </span>
  </div>
</section>
