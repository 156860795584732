<script lang="ts">
  import { MPAY_API_BASE_URI } from '../../env';
  import { mCreditStore } from '../../store/mCreditStore';
  import { MPayApi } from '../constants';
  import { sendRequest } from '../utils/fetch';
  import { getFormattedNumber } from '../utils/formatters';
  import MCreditGraph from './MCreditGraph.svelte';
  import MCreditLimitSection from './MCreditLimitSection.svelte';
  import { t } from 'svelte-i18n';
  import { onMount } from 'svelte';
  import { settingsStore } from '../../store/settingsStore';
  import MCreditDueAmount from './MCreditDueAmount.svelte';
  import { errorDialogStore } from '../../store/errorDialogStore';
  import { topBar } from '../../store/TopBarStore';
  import { Context } from '../utils/analytics/keys';
  import { getAccountId, getCountry } from '../utils/localStorage';
  import Button from './Button.svelte';
  import { navigate } from 'svelte-routing';
  import { routes } from '../../routes';
  import type { CreditLimit } from '../../types/mpay.types';
  import featureFlagsStatus from '../utils/featureFlags';
  import SkeletonLoader from './SkeletonLoader.svelte';

  let isDataLoading = true;
  let mCreditError: unknown | null = null;

  onMount(() => {
    if (!$settingsStore.accountDetails.mcreditAccess) {
      settingsStore.setActiveTab(1);
    }
    fetchCreditLimit();
  });

  $: topBar.setContext(Context.Mcredit);

  async function fetchCreditLimit() {
    isDataLoading = true;
    mCreditError = null;

    try {
      const url = `${MPAY_API_BASE_URI}/${MPayApi.CREDIT_LIMITS}/${getCountry()}/${getAccountId()}`;

      const response = await sendRequest<CreditLimit>(url);
      mCreditStore.setCreditLimit(response);
    } catch (err) {
      mCreditError = err;
      errorDialogStore.setRetryAction(fetchCreditLimit);
    } finally {
      isDataLoading = false;
    }
  }

  const goToRepayment = () => {
    navigate(routes.repayment.path);
  };
</script>

<div>
  {#if isDataLoading}
    <div class="relative mb-14 flex h-[13.3rem] flex-col pt-16">
      <MCreditGraph granted={0} exhausted={0} />
      <SkeletonLoader cssClasses="w-36 mx-auto h-6 rounded mb-5 opacity-75" />
      <SkeletonLoader cssClasses="w-36 mx-auto h-6 rounded mb-7 opacity-75" />
      <SkeletonLoader cssClasses="w-36 mx-auto h-4 rounded opacity-75" />
    </div>
  {:else if !mCreditError}
    <div
      class="relative mb-14 flex h-[13.3rem] flex-col justify-between pt-14"
      data-cy="mcredit-graph"
    >
      <MCreditGraph
        granted={$mCreditStore.creditLimit.grantedCreditLimit}
        exhausted={$mCreditStore.creditLimit.exhaustedCreditLimit}
      />
      <MCreditLimitSection
        title={$t('mcredit.used_limit')}
        value={getFormattedNumber(
          $mCreditStore.creditLimit.exhaustedCreditLimit,
          0
        )}
        currencyCode={$mCreditStore.creditLimit.currencyCode}
      />
      <MCreditLimitSection
        title={$t('mcredit.available_limit')}
        value={getFormattedNumber(
          $mCreditStore.creditLimit.availableCreditLimit,
          0
        )}
        currencyCode={$mCreditStore.creditLimit.currencyCode}
      />
      <MCreditLimitSection
        title={$t('mcredit.granted_credit')}
        value={getFormattedNumber(
          $mCreditStore.creditLimit.grantedCreditLimit,
          0
        )}
        currencyCode={$mCreditStore.creditLimit.currencyCode}
        className={'text-android-label-medium ios:text-ios-caption ios:font-semibold'}
      />
    </div>
  {:else}
    <div
      class="relative mb-14 flex h-[13.3rem] flex-col justify-between pt-14"
      data-cy="mcredit-graph-error"
    >
      <MCreditGraph granted={0} exhausted={0} />
      <MCreditLimitSection
        title={$t('mcredit.used_limit')}
        showCurrency={false}
      />
      <MCreditLimitSection
        title={$t('mcredit.available_limit')}
        showCurrency={false}
      />
      <MCreditLimitSection
        title={$t('mcredit.granted_credit')}
        showCurrency={false}
        className={'text-android-title-small ios:text-ios-body'}
      />
    </div>
  {/if}

  <MCreditDueAmount />

  {#if featureFlagsStatus.repayment}
    <div class="mt-4">
      <Button
        id="repay-btn"
        label={$t('general.cta.repay_open_amount')}
        onClick={goToRepayment}
      />
    </div>
  {/if}
</div>
